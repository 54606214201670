import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Footer from '../Footer';

function Products() {
    const submitButtonStyle = {
        marginTop: "20px",
        backgroundColor: "#219ac7", // React Bootstrap success color
        border: "none",
        borderRadius: "5px",
        padding: "10px 20px",
        fontSize: "16px",
        cursor: "pointer",
    };
 
    const formStyle = {
        maxWidth: "400px",
        margin: "20px auto",
        padding: "20px",
        border: "1px solid #219ac7",
        borderRadius: "10px",
        backgroundColor: "#f5f5f5",
    };
    return (
        <>
            <HeroSection />
            <Form style={formStyle}>
                <Form.Group as={Row}>
                <Form.Label column sm={2}>
                    Subject:
                </Form.Label>
                <Col sm={10}>
                    <Form.Control type="text"
                    placeholder="Enter subject" />
                </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        Name:
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                            type="text"
                            placeholder="Enter your name"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        Email:
                        </Form.Label>
                        <Col sm={10}>
                            <Form.Control
                            type="text"
                            placeholder="Enter your email"
                            />
                        </Col>
                    </Form.Group>
                    <br></br>
                    <textarea placeholder="Enter your message" cols={40} rows={5}/>
                    
                    <Button variant="success"
                    type="submit"
                    style={submitButtonStyle}>
                        Submit</Button>
                    </Form>
                    <Container className='cont'>
                        <p>Contact :- 62723285 / 59927898</p><br></br>
                        <p>Email :- lestho4u@gmail.com</p><br></br>
                        <p>Address :- Leribe 300, Ls4u 101, Box 28</p>
                    </Container>
                <Footer />
        </>
    )
}

export default Products;