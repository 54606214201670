import React from 'react';
import CardItem from './CardItem';
import './Cards.css';

function Cards() {
    return (
        <div className='cards'>
            <h1>Our Services</h1>
            <div className="cards__container">
                <div className="cards__wrapper">
                <ul className='cards__items'>
                    <CardItem
                    src='images/quickloan.png'
                    text='Find the nearest financial services'
                    label='Pokola Hub'
                    />
                    <CardItem
                    src='images/multisalon.png'
                    text='Find the nearest hairdresser'
                    label='Multi Salon'
                    />
                    <CardItem
                    src='images/catchride.png'
                    text='Catch a ride'
                    label='Catch Ride'
                    />
                 </ul>
                 <h1>
                   <a href="https://play.google.com/store/apps/details?id=com.tsekiso.lesothoforu&pcampaignid=web_share">
                        <img src="images/app.png" alt="Ls4u" className="app"></img>
                    Ls4u</a>__
                    <a href="https://play.google.com/store/apps/details?id=com.tsekiso.lesothoapps&pcampaignid=web_share">
                    Les2App<img src="images/app.png" alt="Ls4u" className="app"></img>
                    </a>
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default Cards;
