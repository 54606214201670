import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import { Textfit } from 'react-textfit';

export default function Services() {
    return(
        <>
        <HeroSection />
        <Textfit className='info'><br></br>
            <h2>Information We Collect</h2>
            
            Personal Information :-  We may collect personal information such as your name, email address, and other contact details when you voluntarily provide them to us.
            <br></br>
            Usage Information :- We may collect information about how you use the App, such as your device type, operating system, and interactions with the App.
            <br></br><br></br>
            <h2>How We Use Your Information</h2>
            <br></br>

            We may use the information we collect for the following purposes:

            To provide and maintain the App;
            To personalize your experience;
            To improve our App and services;
            To send you promotional emails and updates;
            To respond to your inquiries and support requests.
            <br></br><br></br>
        
            <h2>Disclosure of Your Information</h2>
            
            We may disclose your information to third parties, including service providers, to help us operate the App and provide services to you.
            <br></br><br></br>
            <h2>Data Retention</h2>
            <br></br>

            We will retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
            <br></br><br></br>
            <h2>Your Choices</h2>
            <p>You can opt-out of receiving promotional emails from us by following 
            the instructions provided in those emails.
             You may also disable cookies on your device, 
             although this may affect your ability to use the App.</p>
             <br></br><br></br>
            <h2>Security</h2>
            <p>We take reasonable measures to protect your information 
                from unauthorized access or disclosure.</p>
                <br></br>

                <h2>Changes to This Privacy Policy</h2>
                <br></br>
                We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                <br></br><br></br>
                <h2>Contact Us</h2>
                If you have any questions about this Privacy Policy, please contact
                 us at [ lestho4u@gmail.com / 62723285 / 59927898 ].
                 <br></br><br></br>
        </Textfit>
        <Footer />
        </>
    )  
}